import * as React from "react"
import { createGlobalStyle } from "styled-components"
import { NavLink, NavExtLink } from "components/NavMenuDesktop"
import { LogoWrap } from "components/HeaderLogo"
import { UnderlineElement } from "components/Ui/Underline"
import SeoComponent from "components/SeoComponent"
import Layout from "components/Layout"
import HeroBanner, { PreHeading } from "components/HeroBanner"
import ImageChecklistSplit from "components/Page/ImageChecklistSplit"
import ImageTextSplit from "components/Page/ImageTextSplit"
import KasProfileGallery from "components/Page/KasProfileGallery"
import KasSegmentsTableSection from "components/Page/KasSegmentsTableSection"
import { homePageData } from "../components/Data/HomePageData"

const HomepageTheme = createGlobalStyle`
  .headroom--unfixed {
    ${NavLink},
    ${NavExtLink} {
      transition: color 0.25s ease;
      
      &::after {
        background-color: #000000;
      }

      &:hover {
        color: #000000;
      }
    }

    ${UnderlineElement} {
      background-color: #000000;
    }    

    header nav.nav-desktop {
      color: #000000;

      a {
        color: #000000;

        &:focus {
          color: #000000;
        }
      }
    }
    .hamburger-inner, .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: #000000;
    }

    ${LogoWrap} {
      svg path {
        &.winr-data-logo_svg__a {
          fill: #ffffff;
        }         
        &.winr-data-logo_svg__b {
          fill: #00d3d0;
        }        
        &.winr-data-logo_svg__c {
          fill: #ffffff;
        }      
      }
    }    
  }

  .headroom--pinned {
    ${LogoWrap} {
      svg path {
        &.winr-data-logo_svg__c {
          fill: #000000;
        }      
      }
    }
  }  
  
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #ffffff;
  }

  ${PreHeading} {
    color: #ffffff;
  }
`

const homePage = () => {
  const page = homePageData

  return (
    <Layout pageName="home-page">
      <SeoComponent
        title="Data &amp; Insights"
        description="WINR Data's Kaptiv&trade; Audience Segments (KAS) is a proprietary data segmentation system used to identify and understand the best audience segments for marketers to focus on - allowing you to predict future performance and deliver higher conversion rates."
      />
      <HomepageTheme />
      {page && page.hero_banner && (
        <HeroBanner
          fields={page.hero_banner}
          pageName="data-and-insights"
        />
      )}

      {page && page.image_checklist_split && (
        <ImageChecklistSplit
          fields={page.image_checklist_split}
          pageName="data-and-insights"
        />
      )}

      {page && page.kas_profile_gallery && (
        <KasProfileGallery
          fields={page.kas_profile_gallery}
          pageName="data-and-insights"
        />
      )}

      {page && page.kas_segments_table_section && (
        <KasSegmentsTableSection
          fields={page.kas_segments_table_section}
          pageName="data-and-insights"
        />
      )}

      {page && page.image_text_split && (
        <ImageTextSplit
          fields={page.image_text_split}
          pageName="data-and-insights"
        />
      )}
    </Layout>
  )
}

export default homePage
