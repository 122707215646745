import BannerImg from "../../../content/assets/winr-data-7-datapoints__light.png"
import KasCodingImg from "../../../content/assets/kas-api-database-infographic.png"
import BellCurveImg from "../../../content/assets/bell-curve-graph.png"
import Profile1Img from "../../../content/assets/profile-rising-stars.png"
import Profile2Img from "../../../content/assets/profile-silver-foxes.png"
import Profile3Img from "../../../content/assets/profile-starting-out.png"
import Profile4Img from "../../../content/assets/kas-segments.jpg"

import Step1Img from "../../../content/assets/kas-conversion-performance-table-2022-before.jpg"
import Step2Img from "../../../content/assets/kas-conversion-performance-table-2022-after.jpg"


const homePageData = {
  hero_banner: {
    heading: 'Data &amp; Insights',
    // background_colour: 'rgb(244, 129, 100)',
    background_colour: '#00d3d0',
    text_colour: '#000',
    subheading: `<p>WINR Data's Kaptiv Audience Segments (KAS) is a proprietary data segmentation system used to identify and understand the best audience segments for marketers to focus on – allowing you to predict future performance and deliver higher conversion rates.</p>`,
    include_image_slider: true,
    slider_images: [
      {
        url: BannerImg,
        alt: ''
      }
    ] 
  },
  image_checklist_split: {
    background_colour: '#f8f8f8',
    text: `<p>KAS offers a depth of actionable insights into current and prospective customers never before seen in performance marketing.</p>`,
    additional_text: `<p>Using KAS, WINR Data is able to:</p>`,
    items: [
      {
        description: 'Identify specifically which audience segments convert best for your business',
        icon: `tick`,
      },
      {
        description: 'Dramatically reduce media spend inefficiency and wastage',
        icon: `tick`,
      },
      {
        description: 'Provide a consistent holistic framework to view your existing & prospective customers',
        icon: `tick`,
      },
      {
        description: 'Identify underperforming segments and better understand churn risks',
        icon: `tick`,
      }                                                      
    ],
    image: {
      url: BellCurveImg,
      alt: ''
    },
    remove_horizontal_margin: false,
    image_alignment: 'left',
    reverse_order_mobile: false
  },   
  kas_profile_gallery: {
    text: `<p>Using multiple <strong>Life Stage</strong>, <strong>Demographic</strong>, <strong>Census</strong> and <strong>Behavioural</strong> variables - customers are assigned into 1 of 24 unique segments.</p>`,
    profile_images: [
      {
        url: Profile1Img,
        alt: 'Rising Stars'
      },
      {
        url: Profile2Img,
        alt: 'Silver Foxes'
      },
      {
        url: Profile3Img,
        alt: 'Starting Out'
      },
      {
        url: Profile4Img,
        alt: 'KAS Segments'
      }
    ]
  },
  kas_segments_table_section: {
    steps: [
      {
        url: Step1Img,
        alt: 'KAS conversion performance table (before)',
        text: `<p>Then, by analysing the conversion performance of your represented customer base, we're able to:</p>`
      },
      {
        url: Step2Img,
        alt: 'KAS conversion performance table (after)',
        checklist_items: [
          {
            checklist_item_text: `Reject lowest performing KAS Segments - thereby lifting overall conversion rates and ROI`,
            icon: 'cross'
          },
          {
            checklist_item_text: `Prioritise future media spend on KAS Segments with the Highest Conversion Index`,
            icon: 'tick'
          },          
        ]
      }      
    ]
  },
  image_text_split: {
    background_colour: `#fdfdfd`, 
    intro_enabled: false,
    intro_text: `<p>WINR Data provides partners with transparent reporting on data usage and revenues by market. Commercial deals are generally done on a per query basis via a secure API web service or an on-premise licence with the end user.</p>`,
    content_type: 'simple_text',
    title: '',
    text: '<p>Finally, KAS coding can be appended to your entire customer/user database via Realtime API (or Batch send) to create a uniform segmentation system and identify additional cross-sell and upsell opportunities.</p>',
    image: {
      url: KasCodingImg,
      alt: ''
    },
    image_alignment: 'left',
    remove_horizontal_margin: false
  }  
}

export {
  homePageData
}