import React from "react"
import styled from "styled-components"
import SwiperCore, { Autoplay, EffectFade } from "swiper"
import Swiper from "react-id-swiper"
import View from "components/View"
import device from "utils/media"

SwiperCore.use([Autoplay, EffectFade])

const KasSectionWrapper = styled.div`
  padding: 50px 0;
  background-color: ${props => props.bgColour};
`

const MainWrapper = styled.div`
  position: relative;
  color: #000000;
  .featured_images {
    height: 100%;
    position: relative;
    .kas-profile__slide {
      height: 100%;
      background-color: ${props => props.bgColour};
      &__inner {
        width: 100%;
        padding-bottom: 89.5%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
    @media ${device.md} {
      width: 94%;
    }
    @media ${device.lg} {
      margin: 0 auto;
    }
  }

  .text_column {
    padding-top: 30px;
    h2 {
      margin-bottom: 50px;
    }

    .simple_text {
      font-size: ${props => props.theme.text.base};
      @media ${device.xl} {
        font-size: ${props => props.theme.text.md};
        padding: 0;
      }
      @media ${device.xxl} {
        font-size: ${props => props.theme.text.md_2};
        padding: 0;
      }
    }
  }
`

const KasProfileGallery = ({ fields, pageName }) => {
  let slides = null

  const sliderParams = {
    spaceBetween: 0,
    centeredSlides: true,
    autoplay: {
      delay: 2800,
      disableOnInteraction: false,
    },    
    loop: true,
    slidesPerView: 1,
    effect: "fade",
  }

  const getSlides = items => {
    const slideItems = []
    Array.from(items).forEach((item, i) => {
      slideItems.push(
        <div className="kas-profile__slide" key={i}>
          <div className="kas-profile__slide__inner">
            <img src={item.url} alt={item.alt} />
          </div>
        </div>
      )
    })
    return slideItems
  }

  slides = fields.profile_images ? getSlides(fields.profile_images) : null

  return (
    <KasSectionWrapper as={View} bgColour="#f8f8f8">
      <MainWrapper pageName={pageName} bgColour="#f8f8f8">
        <div className="container xx-large">
          <div className="row align-items-center-lg">
            <div className="col lg-6 first-xs last-lg">
              <div className="featured_images">
                {slides && <Swiper {...sliderParams}>{slides}</Swiper>}
              </div>
            </div>
            <div className="col lg-6 last-xs first-lg">
              <div className="text_column">
                {fields.text && (
                  <div
                    className={`simple_text simple_text__${pageName}`}
                    dangerouslySetInnerHTML={{ __html: fields.text }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </KasSectionWrapper>
  )
}

export default KasProfileGallery
