import React from "react"
import styled from "styled-components"
import classNames from "classnames"
// import Img from "gatsby-image"
import View from "../View"
import Checklist from "components/Page/Checklist"
import device from "utils/media"

const SectionWrapper = styled.div`
  padding: ${props => (props.zeroPadding ? "0" : "50px 0")};
  background-color: ${props => props.bgColour};
`

const MainWrapper = styled.div`
  position: relative;
  color: #000000;

  .featured_image {
    @media ${device.md} {
      width: 94%;
    }
  }

  .text_column {
    font-size: ${props => props.theme.text.base};
    padding: 30px 0 0 0;

    .simple_text {
      @media ${device.xl} {
        font-size: ${props => props.theme.text.md};
        padding: 0;
      }
      @media ${device.xxl} {
        font-size: ${props => props.theme.text.md_2};
        padding: 0;
      }
    }
  }

  .no-max {
    .featured_image {
      width: 100%;
    }
    .text_column {
      padding: 30px;
      @media ${device.xl} {
        padding: 60px;
      }
      @media ${device.xxl} {
        padding: 80px;
      }
    }
  }
`

const AdditionalText = styled.div`
  margin-bottom: 50px;
  strong {
    font-weight: 500;
  }
  @media ${device.xl} {
    margin-bottom: 0;
    font-size: ${props => props.theme.text.sm};
  }
  @media ${device.xxl} {
    font-size: ${props => props.theme.text.md};
  }
`

const ImageChecklistSplit = ({ fields, pageName }) => {
  const containerClasses = classNames("container", {
    "no-max no-padding": fields.remove_horizontal_margin,
    "xx-large": !fields.remove_horizontal_margin,
  })

  const rowClasses = classNames("row align-items-center-lg", {
    "no-gutter": fields.remove_horizontal_margin,
  })

  const imageColumnClasses = classNames("col lg-6", {
    "last-xs":
      fields.image_alignment === "right" && !fields.reverse_order_mobile,
    "first-xs last-lg":
      fields.image_alignment === "right" && fields.reverse_order_mobile,
    "last-xs first-lg":
      fields.image_alignment === "left" && fields.reverse_order_mobile,
  })

  const textColumnClasses = classNames("col lg-6", {
    "first-xs":
      fields.image_alignment === "right" && !fields.reverse_order_mobile,
    "last-xs first-lg":
      fields.image_alignment === "right" && fields.reverse_order_mobile,
    "first-xs last-lg":
      fields.image_alignment === "left" && fields.reverse_order_mobile,
  })

  return (
    <SectionWrapper
      as={View}
      bgColour={fields.background_colour || "transparent"}
      zeroPadding={fields.remove_horizontal_margin}
    >
      <MainWrapper>
        <div className={containerClasses}>
          <div className={rowClasses}>
            <div className={imageColumnClasses}>
              <div className="featured_image">
                {fields.image && (
                  <img
                    src={fields.image.url}
                    alt={fields.image.alt}
                    title={fields.image.alt}
                  />
                )}
              </div>
            </div>
            <div className={textColumnClasses}>
              <div className="text_column">
                {fields.text && (
                  <div
                    className="simple_text"
                    dangerouslySetInnerHTML={{ __html: fields.text }}
                  />
                )}

                {fields.additional_text && (
                  <AdditionalText
                    dangerouslySetInnerHTML={{ __html: fields.additional_text }}
                  />
                )}
                {fields.items && fields.items.length > 0 && (
                  <Checklist
                    items={fields.items}
                    listType="checkmark_items"
                    itemSize="small"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </SectionWrapper>
  )
}

export default ImageChecklistSplit
