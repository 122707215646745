import React, { useEffect, useState } from "react"
import styled from "styled-components"
import "intersection-observer"
import scrollama from "scrollama"
import device from "utils/media"
import StyledIcon from "components/Ui/StyledIcon"

const KasTableSectionWrapper = styled.div`
  position: relative;
  padding: 50px 0;
  background-color: ${props => props.bgColour};
  z-index: 0;
`

const ScrollContainer = styled.div`
  position: relative;
`

const GalleryInner = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  max-width: 100%;
  margin: 0 auto;
  z-index: 0;
`

const GalleryItem = styled.div`
  background-color: #f8f8f8;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
  }
`

const Steps = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  .section_step {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 60vh;
    position: relative;
    z-index: 99;

    &:last-child {
      margin-bottom: 50vh;
    }

    .text_column {
      padding: 60px 0;
      background-color: rgba(248, 248, 248, 0.9);
      text-align: center;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;

      font-size: ${props => props.theme.text.base};
      padding: 30px 0 0 0;

      .simple_text {
        color: #000000;
        @media ${device.md} {
          width: 60%;
          margin: 0 auto;
        }

        @media ${device.xl} {
          font-size: ${props => props.theme.text.md};
          padding: 0;
        }
        @media ${device.xxl} {
          font-size: ${props => props.theme.text.md_2};
          padding: 0;
        }
      }
    }
  }
`

const ItemsList = styled.div`
  color: #000000;
  margin: 20px auto 40px auto;
  text-align: left;
  @media ${device.md} {
    width: 60%;
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media ${device.sm} {
      display: flex;
      flex-direction: row;
    }

    .item_prepend {
      @media ${device.sm} {
        margin-right: 20px;
      }
    }
  }
`

const ItemDescription = styled.div`
  font-size: ${props => props.theme.text.xs};
  padding-bottom: 2px;
  font-weight: 700;

  @media ${device.md} {
    font-size: ${props => props.theme.text.sm};
  }

  @media ${device.lg} {
    font-size: ${props => props.theme.text.xs};
  }

  @media ${device.xl} {
    font-size: ${props => props.theme.text.sm};
  }

  @media ${device.xxl} {
    font-size: ${props => props.theme.text.base};
  }
`

const KasSegmentsTableSection = ({ fields }) => {
  const [slideCount, setSlideCount] = useState(0)
  const [currentStep, setCurrentStep] = useState(0)
  const [animate, setAnimate] = useState(false)

  const items = fields.steps

  useEffect(() => {
    if (items && items.length) {
      setSlideCount(items.length)
    }
    const scroller = scrollama()
    scroller
      .setup({
        offset: 1,
        step: `.section_step`,
      })
      .onStepEnter(response => {
        setCurrentStep(response.index)
        setAnimate(response.index === slideCount)
      })

    const resizeScroller = () => {
      return scroller.resize()
    }

    window.addEventListener("resize", resizeScroller)

    return () => {
      scroller.destroy()
      window.removeEventListener("resize", resizeScroller)
    }
  }, [items, slideCount])

  return (
    <KasTableSectionWrapper bgColour="#f8f8f8">
      {items && (
        <ScrollContainer className={`container ${animate ? "animate" : ""}`}>
          <GalleryInner>
            {items.map((item, idx) => {
              return (
                <GalleryItem
                  key={idx}
                  className={`gallery-item ${
                    idx === 0 || idx === currentStep ? "visible" : ""
                  }`}
                >
                  <img src={item.url} alt={item.alt} />
                </GalleryItem>
              )
            })}
          </GalleryInner>

          <Steps style={{ zIndex: 99 }}>
            {items.map((stepItem, idx) => {
              return (
                <div className={`section_step`} key={`step-${idx}`}>
                  <div
                    className={`text_column ${
                      idx === currentStep ? "is-active" : ""
                    }`}
                  >
                    {stepItem.text && (
                      <div
                        className="simple_text"
                        dangerouslySetInnerHTML={{
                          __html: stepItem.text || "",
                        }}
                      />
                    )}

                    {stepItem.checklist_items &&
                      stepItem.checklist_items.length > 0 && (
                        <ItemsList>
                          {stepItem.checklist_items.map((item, i) => {
                            return (
                              <div className="item checkmark__item" key={i}>
                                <div className="item_prepend">
                                  {item.icon !== "cross" ? (
                                    <StyledIcon icon="tick" size="small" />
                                  ) : (
                                    <StyledIcon
                                      icon="cross"
                                      bgColor="#fb604b"
                                      size="small"
                                    />
                                  )}
                                </div>
                                <div className="checkmark__item__text">
                                  <ItemDescription
                                    dangerouslySetInnerHTML={{
                                      __html: item.checklist_item_text,
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          })}
                        </ItemsList>
                      )}
                  </div>
                </div>
              )
            })}
          </Steps>
        </ScrollContainer>
      )}
    </KasTableSectionWrapper>
  )
}

export default KasSegmentsTableSection
